import { useContext, useEffect } from 'react'

import { isProduction } from '@hhgtech/hhg-components/constants'
import { clickAndOpenInNewTab } from 'api/dom-utils'
import { getSubotCookieId, getWindowId, validateURL } from 'api/utils'
import axios from 'axios'
import { useCurrentUrl } from 'hooks/useCurrentUrl'
import { assetImagePath } from 'interfaces/constants'
import { GA_TOKEN_COOKIE } from 'interfaces/constants'
import Cookies from 'js-cookie'
import { actionTypes } from 'state/actionTypes'
import { AppContext } from 'state/appContext'
import { NotificationType } from 'state/types'

import { StyledWrap, StyledWrapAction } from './index.styled'

const Chrome = '/images/browser/Chrome.png'
const Edge = '/images/browser/Edge.png'
const Firefox = '/images/browser/Firefox.png'
const IE = '/images/browser/IE.png'
const Opera = '/images/browser/Opera.png'
const Safari = '/images/browser/Safari.png'

function detectBrowserAndLogo() {
  const userAgent = navigator.userAgent.toLowerCase()
  let browserName = ''
  let browserLogo = Chrome
  if (userAgent.indexOf('firefox') > -1) {
    browserName = 'firefox'
    browserLogo = Firefox
  } else if (userAgent.indexOf('chrome') > -1) {
    browserName = 'chrome'
    browserLogo = Chrome
  } else if (userAgent.indexOf('safari') > -1) {
    browserName = 'safari'
    browserLogo = Safari
  } else if (userAgent.indexOf('opera') > -1 || userAgent.indexOf('opr') > -1) {
    browserName = 'opera'
    browserLogo = Opera
  } else if (userAgent.indexOf('edge') > -1) {
    browserName = 'edge'
    browserLogo = Edge
  } else if (userAgent.indexOf('ie') > -1) {
    browserName = 'ie'
    browserLogo = IE
  }

  return { browserLogo, browserName }
}

const baseURL = isProduction
  ? 'https://event-tracking.hellohealthgroup.com'
  : 'https://event-tracking-staging.hellohealthgroup.com'

function NotificationCustom({ data }: { data: NotificationType }) {
  const iconUrl = data.displayIcon || `${assetImagePath}/hhg-logo.png`
  const { dispatch, state } = useContext(AppContext)
  const { browserLogo } = detectBrowserAndLogo()
  const currentUrl = useCurrentUrl()

  useEffect(() => {
    axios
      .post(baseURL + '/v1/custom-event/collect', {
        action: 'push_impression',
        source: 'Webpush Notification',
        ga_client_id: Cookies.get(GA_TOKEN_COOKIE),
        site_code: 'marrybaby',
        email: state.userInfo?.email || '',
        phone: state.userInfo?.phone || '',
        account_id: state.userInfo?.id || 0,
        data: {
          timestamp: new Date().toISOString(),
          device_type: 'web',
          browser_type: 'custom',
          page_url: currentUrl,
          window_id: getWindowId(),
          cookie_id: getSubotCookieId(),
          client_ip: window.clientIP || '',
          client_agent: navigator?.userAgent || '',
          token: window.fcmToken || '',
          campaign_id: data.meta?.campaignId || '',
        },
      })
      .catch(console.log)
  }, [state.userInfo])

  function trackingClickOnSidis() {
    axios
      .post(baseURL + '/v1/custom-event/collect', {
        action: 'push_click',
        source: 'Webpush Notification',
        ga_client_id: Cookies.get(GA_TOKEN_COOKIE),
        site_code: 'marrybaby',
        phone: state.userInfo?.phone || '',
        account_id: state.userInfo?.id || 0,
        email: state.userInfo?.email || '',
        data: {
          timestamp: new Date().toISOString(),
          device_type: 'web',
          browser_type: 'custom',
          page_url: currentUrl,
          window_id: getWindowId(),
          cookie_id: getSubotCookieId(),
          client_ip: window.clientIP || '',
          client_agent: navigator?.userAgent || '',
          token: window.fcmToken || '',
          campaign_id: data.meta?.campaignId || '',
          // This field is different with impression event
          action_url: data.meta?.actionUrl || '',
        },
      })
      .catch(console.log)
  }

  const trackingContent = {
    'data-event-category': 'Web Notification',
    'data-event-action': 'Click',
    'data-event-label': { currentUrl },
  }

  return (
    <StyledWrap>
      <img
        loading="lazy"
        alt="close"
        src={`${assetImagePath}/closeLight.svg`}
        onClick={(evt) => {
          evt.stopPropagation()
          dispatch({
            type: actionTypes.CLEAR_NOTIFICATION,
            payload: { id: data.id },
          })
        }}
        className="notification-close"
        data-event-category="Web Notification"
        data-event-action="Close Click"
        data-event-label={currentUrl}
      />
      <StyledWrapAction
        onClick={() => {
          if (data.meta?.actionUrl && validateURL(data.meta?.actionUrl)) {
            clickAndOpenInNewTab(data.meta.actionUrl)
            dispatch({
              type: actionTypes.CLEAR_NOTIFICATION,
              payload: { id: data.id },
            })
            trackingClickOnSidis()
          }
        }}
        {...trackingContent}
      >
        <img
          loading="lazy"
          src={browserLogo}
          alt=""
          className="notification-logo"
          {...trackingContent}
        />
        <div className="notification-content">
          <p className="notification-title" {...trackingContent}>
            {data.title}
          </p>
          <p className="notification-host" {...trackingContent}>
            {location.host}
          </p>
          <p className="notification-body" {...trackingContent}>
            {data.message}
          </p>
        </div>
        <div className="notification-icon">
          <img loading="lazy" alt="" src={iconUrl} {...trackingContent} />
        </div>
      </StyledWrapAction>
    </StyledWrap>
  )
}

export default NotificationCustom
