import { css } from 'styled-components'

export const atomic = css`
  /* display */
  .d-block {
    display: block;
  }

  /* center */
  .text-center {
    text-align: center;
  }

  /* margin */
  .mx-a {
    margin-left: auto;
    margin-right: auto;
  }

  .-mx-16 {
    margin-left: -16px;
    margin-right: -16px;
  }

  .my-16 {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .my-24 {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .my-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mt-4 {
    margin-top: 4px;
  }

  .mt-8 {
    margin-top: 8px;
  }

  .mt-16 {
    margin-top: 16px;
  }

  .mt-24 {
    margin-top: 24px;
  }

  .mt-32 {
    margin-top: 32px;
  }

  .mt-40 {
    margin-top: 40px;
  }

  .mb-32 {
    margin-bottom: 32px;
  }

  /* padding */
  .pt-0 {
    padding-top: 0 !important;
  }

  .px-16 {
    padding-left: 16px;
    padding-right: 16px;
  }

  .pt-4 {
    padding-top: 4px;
  }

  .pl-16 {
    padding-left: 16px;
  }
  .pointer-event-child-none {
    * {
      pointer-events: none;
    }
  }
`
