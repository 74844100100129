import { RewriteFrames } from '@sentry/integrations'
import * as Sentry from '@sentry/node'

export const init = () => {
  if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    const integrations = []
    if (
      process.env.NEXT_IS_SERVER === 'true' &&
      process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR
    ) {
      // For Node.js, rewrite Error.stack to use relative paths, so that source
      // maps starting with ~/_next map to files in Error.stack with path
      // app:///_next
      integrations.push(
        new RewriteFrames({
          iteratee: (frame) => {
            frame.filename = frame.filename.replace(
              process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR,
              'app:///',
            )
            frame.filename = frame.filename.replace('.next', '_next')
            return frame
          },
        }),
      )
    }

    Sentry.init({
      enabled: process.env.NEXT_PUBLIC_DEPLOY_ENV !== 'development',
      environment: process.env.NEXT_PUBLIC_DEPLOY_ENV,
      integrations,
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      beforeSend: function (event) {
        // Drop all events if query string includes `fbclid` string
        if (
          typeof window !== 'undefined' &&
          window.location.search.indexOf('fbclid') !== -1
        )
          return null
        // Otherwise just let it though
        return event
      },
    })
  }
}
