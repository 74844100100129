import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`


  html {
    min-width: 320px;
    font-family: ${(props: any) =>
      props.theme.locale === 'km-KH' || props.theme.locale === 'ms-MY'
        ? 'Battambang,'
        : props.theme.locale !== 'id-ID'
        ? 'Inter,'
        : ''} 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html,
  body,
  div,
  span,
  object,
  iframe,
  figure,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  code,
  em,
  img,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  b,
  u,
  i,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  main,
  canvas,
  embed,
  footer,
  header,
  nav,
  section,
  video {
    padding: 0;
    border: 0;
    margin: 0;
    font-size: 100%;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    text-size-adjust: none;
    vertical-align: baseline;
  }

  footer,
  header,
  nav,
  section,
  main {
    display: block;
  }

  body {
    line-height: 1;
  }

  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  input {
    margin: 0;
    -webkit-appearance: none;
    border-radius: 0;
  }

  input,
  button,
  textarea,
  select {
    display: block;
    width: 100%;
    padding: 0;
    border: 0;
    margin: 0;
    appearance: none;
    background: none;
    border-radius: 0;
    color: inherit;
    font: inherit;
    letter-spacing: inherit;
    outline: none;
  }

  button:focus,
  input:focus,
  div:focus {
    outline: none;
  }

  img {
    width: 100%;
    height: 100%;
  }

  img:not([src]):not([srcset]) {
    visibility: hidden;
  }
  
  .noscroll {
    overflow: hidden;
  }

  /* Make clicks pass-through */
  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {

    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;

    width: 100%;
    height: 3px;
    background: #2d87f3;
  }

  /* Fancy blur effect */
  #nprogress .peg {
    position: absolute;
    right: 0px;
    display: block;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #2d87f3, 0 0 5px #2d87f3;
    opacity: 1.0;

    -webkit-transform: rotate(3deg) translate(0px, -4px);
        -ms-transform: rotate(3deg) translate(0px, -4px);
            transform: rotate(3deg) translate(0px, -4px);
  }

  /* Remove these to get rid of the spinner */
  #nprogress .spinner {
    position: fixed;
    z-index: 1031;
    top: 15px;
    right: 15px;
    display: block;
  }

  #nprogress .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;

    border: solid 2px transparent;
    border-top-color: #2d87f3;
    border-left-color: #2d87f3;

    -webkit-animation: nprogress-spinner 400ms linear infinite;
            animation: nprogress-spinner 400ms linear infinite;
    border-radius: 50%;
  }

  .nprogress-custom-parent {
    position: relative;
    overflow: hidden;
  }

  .nprogress-custom-parent #nprogress .spinner,
  .nprogress-custom-parent #nprogress .bar {
    position: absolute;
  }

  @-webkit-keyframes nprogress-spinner {
    0%   { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  @keyframes nprogress-spinner {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance:none;
  }

  amp-next-page {
    margin-top: -18px;
  }

  .amp-text-large {
    p {
      margin-bottom: 1.2em;
      font-size: 1.2em;
    }

    &.amp-text-normal {
      p {
        margin-bottom: 1.2em;
        font-size: 1em;
      }
    }
  }

  /* AMP Sidebar */
  [amp] body > div:last-child {
    z-index: 100;
  }

  .amp-header {
    position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100vw;
  max-width: 100vw;
  }

`
export default GlobalStyle
