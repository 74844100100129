import { useEffect } from 'react'

function useServerLogs(pageProps: any) {
  useEffect(() => {
    if (!pageProps?.collectServerLogs) {
      return
    }
    console.group('Server Side NextJs Logs')
    pageProps.collectServerLogs.map?.((logs: any, idx: number) => {
      console.log(`${idx + 1}. `, ...logs)
    })
    console.groupEnd()
  }, [])
}

export { useServerLogs }
