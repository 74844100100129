import { theme } from '@hhgtech/hhg-components/misc'
import { createGlobalStyle } from 'styled-components'

import { atomic } from './atomic'

const GlobalStyle = createGlobalStyle`


  html {
    min-width: 320px;
    font-family: ${(props: any) =>
      props.theme.locale === 'km-KH' || props.theme.locale === 'ms-MY'
        ? 'Battambang,'
        : props.theme.locale !== 'id-ID'
        ? 'Inter,'
        : ''} 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    scroll-behavior: smooth;
  }
  h1, h2, h3, h4, h5 {
    font-family: Krub, -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html,
  body,
  div,
  span,
  object,
  iframe,
  figure,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  code,
  em,
  img,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  b,
  u,
  i,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  main,
  canvas,
  embed,
  footer,
  header,
  nav,
  section,
  video {
    padding: 0;
    border: 0;
    margin: 0;
    font-size: 100%;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    text-size-adjust: none;
    vertical-align: baseline;
  }

  footer,
  header,
  nav,
  section,
  main {
    display: block;
  }

  body {
    line-height: 1;
  }

  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  input {
    margin: 0;
    -webkit-appearance: none;
    border-radius: 0;
  }

  input,
  button,
  textarea,
  select {
    display: block;
    /* width: 100%; */
    padding: 0;
    border: 0;
    margin: 0;
    appearance: none;
    background: none;
    border-radius: 0;
    color: inherit;
    font: inherit;
    letter-spacing: inherit;
    outline: none;
  }

  button:focus,
  input:focus,
  div:focus {
    outline: none;
  }

  img {
    width: 100%;
    height: 100%;
  }

  img:not([src]):not([srcset]) {
    visibility: hidden;
  }
  
  .noscroll {
    overflow: hidden;
  }
  .pointer{
    cursor: pointer;
  }

  .non-pointer-event{
    pointer-events: none;
  }

  .non-pointer-event-child * {
    pointer-events: none;
  }

  svg *{
    pointer-events: none;
  }

  /* Make clicks pass-through */
  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    
    width: 100%;
    height: 3px;
    background-color: #e85388 !important;
  }

  /* Fancy blur effect */
  #nprogress .peg {
    position: absolute;
    right: 0px;
    display: block;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #e85388, 0 0 5px #e85388;
    opacity: 1.0;

    -webkit-transform: rotate(3deg) translate(0px, -4px);
        -ms-transform: rotate(3deg) translate(0px, -4px);
            transform: rotate(3deg) translate(0px, -4px);
  }

  /* Remove these to get rid of the spinner */
  #nprogress .spinner {
    position: fixed;
    z-index: 1031;
    top: 15px;
    right: 15px;
    display: block;
  }

  #nprogress .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;

    border: solid 2px transparent;
    border-top-color: #e85388;
    border-left-color: #e85388;

    -webkit-animation: nprogress-spinner 400ms linear infinite;
            animation: nprogress-spinner 400ms linear infinite;
    border-radius: 50%;
  }

  .nprogress-custom-parent {
    position: relative;
    overflow: hidden;
  }

  .nprogress-custom-parent #nprogress .spinner,
  .nprogress-custom-parent #nprogress .bar {
    position: absolute;
  }

  .styled-scrollbar {
    ::-webkit-scrollbar {
      width: 4px;
      background-color: inherit;
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${theme.mbColors.midGray};
      border-radius: 10px;

      &:hover {
        background-color: ${theme.mbColors.gray};
      }
    }
  }

  @-webkit-keyframes nprogress-spinner {
    0%   { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  @keyframes nprogress-spinner {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance:none;
  }

  amp-next-page {
    margin-top: -18px;
  }

  .amp-text-large {
    p {
      margin-bottom: 1.2em;
      font-size: 1.2em;
    }

    &.amp-text-normal {
      p {
        margin-bottom: 1.2em;
        font-size: 1em;
      }
    }
  }

  /* AMP Sidebar */
  [amp] body > div:last-child {
    z-index: 100;
  }

  .amp-header {
    position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100vw;
  max-width: 100vw;
  }

  @keyframes leftAppears {
    from {
      left: -20%;
      opacity: 0;
    }
    to {
      left: 0;
      opacity: 1;
    }
  }

  @keyframes leftDisappears {
    from {
      left: 0;
      opacity: 1;
    }
    to {
      left: -20%;
      opacity: 0;
    }
  }

  @keyframes rightAppears {
    from {
      right: -20%;
      opacity: 0;
    }
    to {
      right: 0;
      opacity: 1;
    }
  }

  @keyframes rightDisappears {
    from {
      right: 0;
      opacity: 1;
    }
    to {
      right: -20%;
      opacity: 0;
    }
  }

  @keyframes fading {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  #EZDrawer {
    #EZDrawer__checkbox {
        display: none;

        &:checked {
            & ~ #EZDrawer__overlay {
                display: block;
                opacity: 1;
            }

            & ~ #EZDrawer__container {
                transform: translate3d(0, 0, 0) !important;
                visibility: visible;
            }
        }
    }

    #EZDrawer__overlay {
        position: fixed;
        top: 0;
        left: 0;
        display: none;
        width: 100%;
        height: 100vh;
    }

    #EZDrawer__container {
        position: fixed;
        background: white;
        box-shadow: 0 0 10px 5px rgba($color: #000000, $alpha: 0.1);
        transition: all;
        visibility: hidden;
    }
  }

  //.wrapper-menu-category > a *, .wrapper-menu-community *, .wrapper-menu-health-tool *{
    //pointer-events: none;
  //}
  
  .btn {
    width: 100%;
    margin-top: 16px;
  }

  .share-popup a {
    text-decoration: none !important;
  }

  .share-popup .__copy {
    img {
      width: 24px !important;
      height: 24px !important;
    }
  }
  a {
    color: inherit;
    text-decoration: inherit;
  }

  html.ad-noscroll body {
    overflow: hidden !important;
  }

  ${atomic}
`
export default GlobalStyle
