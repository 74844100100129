import { domainLocales } from 'domainLocales'
import { useCategory } from 'hooks/useCategory'
import { useRouterExtend } from 'hooks/useRouterExtend'
import Head from 'next/head'

export const CustomScripts = () => {
  const { locale } = useRouterExtend()
  const { isParenting, isPregnancy } = useCategory()
  return (
    <>
      <Head>
        <script
          key="su-script"
          id="su-script"
          src={
            (process.env.NEXT_PUBLIC_SUBOT_URL ||
              'https=//subot.marrybaby.vn/') + 'subot.js'
          }
          defer
          data-api={
            process.env.NEXT_PUBLIC_SUBOT_URL || 'https://subot.marrybaby.vn/'
          }
          data-cookie-domain={domainLocales[locale]}
          data-site-id={10}
        />
        <script
          key="gpt-script"
          async
          src={'https://securepubads.g.doubleclick.net/tag/js/gpt.js'}
        />
        <script
          key="gsi-script"
          async
          src="https://accounts.google.com/gsi/client"
        />

        {!isParenting && !isPregnancy && (
          <script
            defer
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `(function(){var o='script',s=top.document,a=s.createElement(o),m=s.getElementsByTagName(o)[0],d=new Date(),timestamp=""+d.getDate()+d.getMonth()+d.getHours();a.async=1;a.src='https://cdn4-hbs.affinitymatrix.com/hvrcnf/marrybaby.vn/'+ timestamp + '/index?t='+timestamp;m.parentNode.insertBefore(a,m)})();`,
            }}
          />
        )}
      </Head>
      <script
        defer
        dangerouslySetInnerHTML={{
          __html: `
            var homeSearch = document.getElementById("search-input-home-temp");
              homeSearch && homeSearch.addEventListener("keyup", function(event) {
                  if (event.keyCode === 13) {
                    window.location.href = "/search?s=" + homeSearch.value + "&page=1"
                  }
              });

              // Open comment popup
              var element = document.getElementById('comment-popup-trigger')
              var commentPopupCloseButton = document.getElementById('comment-popup-close-button')
              var popup = document.querySelector('.comment-popup')
              var commentWrapperPopup = document.querySelector('.comment-popup-wrapper')
              function openPopup() {
                popup.setAttribute('data-open', 'true')
                commentWrapperPopup.setAttribute('data-open', 'true')
              }
              function closePopup() {
                popup.setAttribute('data-open', 'false')
                commentWrapperPopup.setAttribute('data-open', 'false')
              }
              if (element && popup && commentWrapperPopup) {
                element.addEventListener('click', openPopup)
              }
              if(commentPopupCloseButton && popup && commentWrapperPopup) {
                commentPopupCloseButton.addEventListener('click', closePopup)
                commentWrapperPopup.addEventListener('click', closePopup)
              }
              // PUBMATIC AD CODE
              (function() {
                var purl = window.location.href;
                var url = '//ads.pubmatic.com/AdServer/js/pwt/158621/6948';
                var profileVersionId = '';
                if(purl.indexOf('pwtv=')>0){
                  var regexp = /pwtv=(.*?)(&|$)/g;
                  var matches = regexp.exec(purl);
                  if(matches.length >= 2 && matches[1].length > 0){
                    profileVersionId = '/'+matches[1];
                  }
                }
                var wtads = document.createElement('script');
                wtads.async = true;
                wtads.type = 'text/javascript';
                wtads.src = url+profileVersionId+'/pwt.js';
                var node = document.getElementsByTagName('script')[0];
                node.parentNode.insertBefore(wtads, node);
              })();
            `,
        }}
      />
    </>
  )
}
