import { theme } from '@hhgtech/hhg-components'
import styled from 'styled-components'

export const StyledWrap = styled.div`
  position: relative;

  .notification-close {
    position: absolute;
    width: 20px;
    height: 20px;
    padding: 3px;
    top: -9px;
    left: -9px;
    z-index: 1;
    border-radius: 50%;
    background-color: ${theme?.colors.neutral150};
    opacity: 0;
    transition: 0.3s ease;
    pointer-events: none;
    cursor: pointer;
  }

  &:hover .notification-close {
    opacity: 1;
    pointer-events: auto;
  }
`

export const StyledWrapAction = styled.div`
  display: flex;
  position: relative;
  width: 345px;
  max-width: 100%;
  border-radius: 16px;
  padding: 10px 15px;
  margin-bottom: 15px;
  border: 1px solid white;
  cursor: pointer;
  background-color: ${theme?.colors.neutral150};
  box-shadow: rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.06) 0px 0px 2px,
    rgba(0, 0, 0, 0.04) 0px 0px 1px;

  .notification-content {
    padding-left: 14px;
    padding-right: 14px;
    margin-right: auto;
  }

  .notification-title,
  .notification-host {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: ${theme?.colors.gray800};
    word-break: break-all;
  }

  .notification-body {
    font-size: 12px;
    line-height: 18px;
    color: ${theme?.colors.gray800};
  }

  .notification-logo {
    width: 32px;
    min-width: 32px;
    height: 32px;
    object-fit: cover;
    align-self: center;
  }

  .notification-icon {
    width: 32px;
    min-width: 32px;
    height: 32px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px;
    }
  }
`
