import Cookies from 'js-cookie'

import { BEARER_TOKEN_COOKIE, CORAL_TOKEN_COOKE } from '../interfaces/constants'
import { actionTypes } from './actionTypes'
import { initialState } from './initialState'
import { State, Action } from './types'

const appReducer = (state: State = initialState, action: Action): State => {
  const { NEXT_PUBLIC_DEPLOY_ENV: DEPLOY_ENV } = process.env
  const isDev = DEPLOY_ENV === 'development'

  switch (action.type) {
    case actionTypes.LOGIN:
      return {
        ...state,
        userInfo: action.payload,
        isLoggedIn: true,
      }
    case actionTypes.LOGOUT:
      Cookies.remove(BEARER_TOKEN_COOKIE, {
        path: '/',
        domain: isDev ? 'localhost' : action.payload.domain,
      })
      Cookies.remove(CORAL_TOKEN_COOKE, {
        domain: isDev ? 'localhost' : action.payload.domain,
      })
      return {
        ...state,
        userInfo: null,
        isLoggedIn: false,
      }
    case actionTypes.ADD_BOOKMARKS:
      if (!action.payload.bookmarks || !state.userInfo) return state
      const newBookmark = Array.from(
        new Set([...state.newBookmark, action.payload.bookmarkId]),
      )
      return {
        ...state,
        newBookmark,
        userInfo: {
          ...state.userInfo,
          bookmarks: Array.from(
            new Set(
              (state.userInfo.bookmarks || []).concat(action.payload.bookmarks),
            ),
          ),
          total_bookmark: (state.userInfo?.total_bookmark || 0) + 1,
          first_time_bookmark: Boolean(action.payload.isFirstTimeBookmark),
        },
      }
    case actionTypes.REMOVE_BOOKMARKS:
      if (!action.payload.bookmarks || !state.userInfo) return state
      const removeBookmarks = state.newBookmark.filter(
        (item) => item !== action.payload.bookmarkId,
      )
      return {
        ...state,
        newBookmark: removeBookmarks,
        userInfo: {
          ...state.userInfo,
          bookmarks: (state.userInfo.bookmarks || []).filter(
            (b) => !action.payload.bookmarks.includes(b),
          ),
          total_bookmark:
            (state.userInfo?.total_bookmark || 0) -
            (state.newBookmark.includes(action.payload.bookmarkId) ? 1 : 0),
        },
      }
    case actionTypes.SET_IS_MOBILE_SSR:
      return {
        ...state,
        isMobileSsr: action.payload.isMobileSsr,
      }
    case actionTypes.SET_OPEN_LOGOUT_POPUP:
      return {
        ...state,
        openLogoutPopup: action.payload.openLogoutPopup,
      }
    case actionTypes.SET_COMMENT_FOCUS:
      return {
        ...state,
        focusCommentId: action.payload.focusCommentId,
      }

    case actionTypes.PUSH_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
      }
    case actionTypes.CLEAR_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (noti) => noti.id !== action.payload.id,
        ),
      }
    case actionTypes.SET_CLOSE_SITEWIDE_BANNER:
      return {
        ...state,
        sitewideClose: {
          ...state.sitewideClose,
          [action.payload?.type]: action.payload?.close ?? true,
        },
      }
    default:
      return state
  }
}

export { appReducer }
