import { memo } from 'react'

// FOR INSKIN AD
export const InSkinScript = memo(function InSkinScriptComp() {
  return (
    <script
      id="inskin-script"
      dangerouslySetInnerHTML={{
        __html: `
          var fn_pageskin = "false";
          if (screen.width >= 1280) {
              fn_pageskin = "true";
          }
          window.googletag = window.googletag || { cmd: [] };
          googletag.cmd.push(function () {
            googletag.pubads().setTargeting("inskin_yes",fn_pageskin);
          });`,
      }}
    />
  )
})
