import { useMemo } from 'react'

import { MAPPED_CATEGORY_SLUGS } from 'interfaces/constants'

import { useRouterExtend } from './useRouterExtend'
export function getCategoryFromURL(
  url: string,
  categories: Record<string, string>,
): string | null {
  const urlParts = url.split('/')

  Object.keys(categories).find((key) => {
    const keyword = categories[key]
    if (keyword && urlParts.includes(keyword)) {
      return key
    }
  })

  return null // Return null if no matching key is found
}

export const useCategory = () => {
  const { asPath, locale } = useRouterExtend()
  const isPregnancy = useMemo(() => {
    const targetSlug = MAPPED_CATEGORY_SLUGS[locale]?.pregnancy
    return !!(
      targetSlug && decodeURI(asPath.replace(/\//gi, '')).includes(targetSlug)
    )
  }, [])

  const isParenting = useMemo(() => {
    const targetSlug = MAPPED_CATEGORY_SLUGS[locale]?.parenting
    return !!(
      targetSlug && decodeURI(asPath.replace(/\//gi, '')).includes(targetSlug)
    )
  }, [])

  const type = useMemo<string | null>(() => {
    return getCategoryFromURL(asPath, MAPPED_CATEGORY_SLUGS[locale] || {})
  }, [asPath, locale])
  return { isPregnancy, isParenting, type }
}
