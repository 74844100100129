import { useEffect } from 'react'

import { useFbDataLayer } from '@hhgtech/hhg-components/hooks'
import { PATHS } from 'api/paths'
import { authorize, callApi, callApiWithAuth, getSSOPath } from 'api/utils'
import { domainLocales } from 'domainLocales'
import {
  ADVERTISING_TOKEN_COOKIE,
  BEARER_TOKEN_COOKIE,
  GA_TOKEN_COOKIE,
  REFRESH_TOKEN_COOKIE,
  SSO_GOOGLE_CLIENT_ID,
  USER_TOKEN_COOKIE,
} from 'interfaces/constants'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { useIntl } from 'react-intl'
import { actionTypes } from 'state/actionTypes'

import { useAppContext } from './useAppContext'

export const useAppHooks = () => {
  const { state, dispatch, pushNotifications } = useAppContext()
  const { userInfo } = state
  const { formatMessage: f } = useIntl()
  const { locale = 'vi-VN' } = useRouter()
  useEffect(() => {
    if (
      typeof userInfo !== 'undefined' &&
      !userInfo?.id &&
      typeof google !== 'undefined'
    ) {
      const handleCredentialResponse = async (response: any) => {
        if (response?.credential) {
          const authorizeResponse = await callApi(
            getSSOPath(PATHS.POST_SSO_VERIFY_TOKEN),
            'POST',
            {
              data: {
                id_token: response?.credential,
                returnUrl: window.location.href,
                ga_client_id: Cookies.get(GA_TOKEN_COOKIE),
              },
            },
          )
          if (authorizeResponse && authorizeResponse?._data) {
            Cookies.set(BEARER_TOKEN_COOKIE, authorizeResponse._data, {
              domain:
                window.location.hostname === 'localhost'
                  ? 'localhost'
                  : `.${domainLocales[locale]}`,
            })
            const user = await callApiWithAuth(
              getSSOPath(PATHS.GET_PROFILE),
              'POST',
            )
            if (user?._data?.id)
              dispatch({
                type: actionTypes.LOGIN,
                payload: user?._data,
              })
          } else {
            pushNotifications({
              message:
                f({ id: 'notification.comment.somethingWrong' }) ||
                'Something went wrong.',
              type: 'danger',
            })
          }
        }
      }

      window.google.accounts.id.initialize({
        client_id: SSO_GOOGLE_CLIENT_ID,
        callback: handleCredentialResponse,
        auto_select: false,
        context: 'signin',
        prompt_parent_id: 'google-onetap-container',
        cancel_on_tap_outside: false,
      })
      window.google.accounts.id.prompt()
    }
  }, [userInfo?.id])

  useEffect(() => {
    authorize(document.cookie).then((userInfoApi) => {
      if (userInfoApi) {
        dispatch({
          type: actionTypes.LOGIN,
          payload: userInfoApi,
        })
      } else {
        dispatch({
          type: actionTypes.LOGOUT,
          payload: {
            domain: '.' + domainLocales[locale || 'vi-VN'],
          },
        })
      }
    })
  }, [])

  useEffect(() => {
    // remove old legacy token
    Cookies.remove('__uid2_advertising_token')
    if (userInfo?.id) {
      callApiWithAuth(getSSOPath(PATHS.GET_TOKENS_UID2), 'POST').then(
        (response) => {
          if (response?._status === 1 && response?._data) {
            const {
              // unified_id,
              unified_id,
              unified_identity_expires,
              unified_refresh,
              unified_refresh_expires,
              unified_refresh_from,
              unified_refresh_response_key,
              unified_user_token,
            } = response?._data

            if (response?._data) {
              Cookies.set(
                ADVERTISING_TOKEN_COOKIE,
                JSON.stringify({
                  advertising_token: unified_id || '',
                  refresh_token: unified_refresh || '',
                  identity_expires: unified_identity_expires || 0,
                  refresh_from: unified_refresh_from || 0,
                  refresh_expires: unified_refresh_expires || 0,
                  refresh_response_key: unified_refresh_response_key || '',
                }),
                {
                  domain: `.${domainLocales[locale]}`,
                },
              )

              // wait for pbjs to be available and set the the whole json object
              let timeout: ReturnType<typeof setTimeout> | null = null
              function setUid2() {
                if (timeout) clearTimeout(timeout)
                if (
                  typeof window.pbjs !== 'undefined' &&
                  typeof window.pbjs.setConfig !== 'undefined'
                ) {
                  window.pbjs.setConfig({
                    userSync: {
                      userIds: [
                        {
                          name: 'uid2',
                          params: {
                            uid2ServerCookie: ADVERTISING_TOKEN_COOKIE,
                          },
                        },
                      ],
                    },
                  })
                } else {
                  timeout = setTimeout(function () {
                    setUid2()
                  }, 300)
                }
              }
              setUid2()
            }

            Cookies.set(REFRESH_TOKEN_COOKIE, unified_refresh, {
              domain: `.${domainLocales[locale]}`,
            })

            Cookies.set(USER_TOKEN_COOKIE, unified_user_token, {
              domain: `.${domainLocales[locale]}`,
            })
          }
        },
      )
    }
  }, [userInfo?.id])

  useFbDataLayer(userInfo)
}

export const AppHooks = () => {
  useAppHooks()
  return null
}
