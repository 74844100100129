// import { UserInfo } from 'interfaces/types'

import { State } from './types'

export const initialState: State = {
  isLoggedIn: false,
  isMobileSsr: true,
  userInfo: undefined,
  openLogoutPopup: false,
  newBookmark: [],
  notifications: [],
  sitewideClose: {
    article: false,
    category: false,
    home: false,
    other: false,
  },
}
